import MediaSources from "../../media_sources";
import { parseUrlParams } from './../../../core/library/url';


export const test_config = {
    SHOW_LANGS: true,
    BASE_DOMAIN: 'https://stage.arteldoc.com',
    APP_CLASS: 'vm',
    PROJECT_NAME: 'vm',
    PROJECT_PREFIX: 'vm',
    LOCALE: "ru",
    PREVIEW_DOMAIN: "http://localhost:8082",
    PREVIEW_HASH: "AE30845FBF6EE09305710B753A9C264C",
    SOCKET: 'http://localhost:9001',

    PROJECT: {
        // project name
        NAME: 'vm',
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `http://localhost:9106/api/backend`,
        ELASTIC_API: `http://localhost:9106/api/backend/elastic`,
    },


    OAUTH: {
        // url for oauth domain
        URL: `https://auth.rt.com/`,
        // current project domain
        PROJECT_DOMAIN: `localhost:3000`,
        // current project url path
        PROJECT_PATH: `http://localhost:3000`,
        // path for oauth api
        API: `https://auth.rt.com/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://auth.rt.com/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },

    ELASTIC: {
        API: "http://localhost:9106/api/backend/elastic"
    },


    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    UPLOAD: {
        "path": "http://localhost:9106/api/backend/media"
    },

    // vmru config
    VIEWS: {
        documents: {
            "article": "NewsController",
            "authors": "authors_view",
            "hubauthors": "authors_view",
            "category": "category_view",
            "hubcats": "HubCategoryView",
            "country": "country_view",
            "tag": "tag_view",
            "tags": "category_view",
            "manufacture": "category_view",
            "award": "category_view",
            "trends": "category_view",
            "contacts_tags": "tag_view",
            "label": "category_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "news": "NewsController",
            "languages": "languages_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "mcat": 'anons_view',
            "polls": 'poll',
            "covid": "covid",
            "stop": "stopwords",
            "contacts": "contacts_index",
            "contacts_type": "contacts_types",
            "redirects": "RedirectsViews",
            "stories": "NewsController",
        },
        documents_add: {
            "quiz": "AddQuiz",
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "hubauthors": "HubAddAuthor",
            "authors": "add_author",
            "category": "add_category",
            "hubcats": "HubAddCategory",
            "country": "AddCountry",
            "tags": "add_category",
            "trends": "add_category",
            "tag": "add_tag",
            "label": "AddLabel",
            "manufacture": "add_category",
            "mcat": "anons_add",
            "films": "add_films",
            "arteldoc": "add_films",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "covid": "covid",
            "afisha": "afisha_vm",
            "regionmat": "region_material",
            "mgallery": "mgallery",
            "stories": "add_news",
            "stories_cards": "StoriesCards",
            "receipt": "receipt",
            "stt": "stt",
            "stop": "add_stopwords",
            "contacts": "add_contacts",
            "redirects": "RedirectsAdd",
            "languages": "AddLanguage",
            "serials": "AddSerials",
            "episodes": "AddEpisode",
            "award": "AddAward",
            "hselections": "AddHSelection",
        }
    },
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',

        },

        {
            'title': 'KINOHUB',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'FILMS',
                    'path': '/documents/films',
                    'icon': 'video-camera',
                },
                {
                    'title': 'SERIALS',
                    'path': '/documents/serials',
                    'icon': 'database',
                },
                {
                    'title': 'SERIALS_EPISODES',
                    'path': '/documents/episodes',
                    'icon': 'video-camera',
                },
                {
                    'title': 'ARTELDOC',
                    'path': '/documents/arteldoc',
                    'icon': 'video-camera',
                },
            ]
        },

        {
            "title": "HUB",
            'icon': 'container',
            'submenu': [
                {
                    'title': 'NEWS',
                    'path': '/documents/news',
                    'icon': 'file-text',
                },
                {
                    'title': 'STORIES',
                    'path': '/documents/stories',
                    'icon': 'file-text',
                },
                {
                    'title': 'STORIES_CARDS',
                    'path': '/documents/stories_cards',
                    'icon': 'container',
                },
                {
                    'title': 'HSELECTIONS',
                    'path': '/documents/hselections',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'QUIZ',
                    'path': '/documents/quiz',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'AUTHORS',
                    'path': '/documents/hubauthors',
                    'icon': 'user-add',
                },
            ]
        },

        {
            "title": "DICTS_KINOHUB",
            "icon": "database",
            "submenu": [
                {
                    "title": "LANGUAGES",
                    "path": "/documents/languages",
                    "icon": "global",
                },
                {
                    "title": "Country",
                    "path": "/documents/country",
                    "icon": "global",
                },
                {
                    'title': "CATEGORIES",
                    "path": "/documents/category",
                    "icon": "file-text"
                },
                {
                    'title': "TAGS",
                    "path": "/documents/tags",
                    "icon": "file-text"
                },
                {
                    'title': "MANUFACTURE",
                    "path": "/documents/manufacture",
                    "icon": "setting"
                },
                {
                    'title': "TRENDS",
                    "path": "/documents/trends",
                    "icon": "file-text"
                },
                {
                    'title': "AUTHORS",
                    "path": "/documents/authors",
                    "icon": "user"
                },
                {
                    'title': "AWARDS",
                    "path": "/documents/award",
                    "icon": "crown"
                },
                {
                    'title': "LABELS",
                    "path": "/documents/label",
                    "icon": "database"
                }
            ]
        },
        {
            title: "INSTRUMENTS",
            icon: "code",
            'submenu': [
                {
                    title: "COLLAGE_MAKER",
                    path: "/collage_maker",
                    icon: "picture",
                    component: 'CollageMaker',
                },
                {
                    'title': 'REDIRECTS',
                    'path': '/documents/redirects',
                    'icon': 'experiment',
                },
                {
                    title: 'MEDIA_GALLERY_LIST',
                    path: '/mediagallery',
                    icon: 'picture',
                    component: 'media_gallery',
                },
                {
                    title: 'CONVERTER',
                    path: '/converter',
                    icon: 'video-camera',
                    component: 'ConverterIndex',
                },
            ]
        },
        {
            'title': 'PUSH',
            'path': '/push',
            'icon': 'notification',
            'component': 'push',
        },
        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'database',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WTYPE_ALL',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WTYPE_MAIN_PAGE',
                    'path': '/widgets/type/main',
                    'icon': 'appstore',
                },
                {
                    'title': 'WTYPE_VIDEO_PAGE',
                    'path': '/widgets/type/films',
                    'icon': 'appstore',
                },
                {
                    'title': 'WTYPE_SERIALS_PAGE',
                    'path': '/widgets/type/serials',
                    'icon': 'appstore',
                },
                {
                    'title': 'WIDGETS_ADS',
                    'path': '/widgets/type/ads',
                    'icon': 'appstore',
                },

                {
                    'title': 'WIDGETS_HUB_MAIN',
                    'path': '/widgets/type/hub_main',
                    'icon': 'appstore',
                },

                {
                    'title': 'WIDGETS_HUB_MEDIA',
                    'path': '/widgets/type/hub_media',
                    'icon': 'appstore',
                },
            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },
                {
                    'path': '/widgets/type/:type',
                    'component': 'widget',
                },

            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        {
            'title': 'USERS',
            'path': '/profiles',
            'icon': 'user-add',
            'component': 'ArtelProfiles',
        },
        {
            'title': 'COMMENTS',
            'path': '/comments',
            'icon': 'message',
            'component': 'QAComments',
        },
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'permission': 'USERS_ADM',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        }
    ],

    BANNER_TEXT_COLORS: [
        { 'value': 'black',  'label': 'Без цвета'},
        { 'value': 'white',  'label': 'Белый'},
        { 'value': 'pink',  'label': 'Розовый'},
        { 'value': 'red',  'label': 'Красный'},
    ],
    BANNER_BG_COLORS: [
        { 'value': 'bgc--white',  'label': 'Белый'},
        { 'value': 'bgg--white-ttb',  'label': 'Белый градиент сверху-вниз'},
        { 'value': 'bgg--white-ltr',  'label': 'Белый градиент слева-направо'},
        { 'value': 'bgc--pink',  'label': 'Розовый'},
        { 'value': 'bgg--pink-ttb',  'label': 'Розовый градиент сверху-вниз'},
        { 'value': 'bgg--pink-ltr',  'label': 'Розовый градиент слева-направо'},
        { 'value': 'bgc--red',  'label': 'Красный'},
        { 'value': 'bgg--red-ttb',  'label': 'Красный градиент сверху-вниз'},
        { 'value': 'bgg--red-ltr',  'label': 'Красный градиент слева-направо'},
        { 'value': 'bgc--black',  'label': 'Черный'},
        { 'value': 'bgg--black-ttb',  'label': 'Черный градиент сверху-вниз'},
        { 'value': 'bgg--black-ltr',  'label': 'Черный градиент слева-направо'},
    ],
    WORKFLOW: {
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            return "";
            // switch (item.type.toLowerCase()) {
            //     case "news":
            //         return `http://stage.absatz.media/news/${item.publicId}-${item.slug}`;
            //     case "meaning":
            //         return `http://stage.absatz.media/opinion/${item.publicId}-${item.slug}`;
            //     case "episode":
            //         return `http://stage.absatz.media/tv/${item.publicId}-${item.slug}`;
            //     case "gallery":
            //         return `http://stage.absatz.media/photo/${item.publicId}-${item.slug}`;
            //     case "infographic":
            //         return `http://stage.absatz.media/infographic/${item.publicId}-${item.slug}`;
            //     case "battle":
            //         return `http://stage.absatz.media/battle/${item.publicId}-${item.slug}`;
            //     case "show":
            //         return ``;
            //     case "newspaper":
            //         return ``;
            //     case "rnewspaper":
            //         return ``;
            //     case "schedule":
            //         return ``;
            //     case "author":
            //         return `http://stage.absatz.media/author/${item.publicId}-${item.slug}`;
            //     case "trend":
            //         return `http://stage.absatz.media/trend/${item.publicId}`;
            //     case "category":
            //         return `http://stage.absatz.media/${item.slug}`;
            //     case "tag":
            //         return `http://stage.absatz.media/tag/${item.slug}`;
            //     case "page":
            //         return `http://stage.absatz.media/${item.slug}`;
            //     default:
            //         return `https://stage.absatz.media/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            // }
        },
        SHOW_SEARCH: true,
        STATUSES: ['READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT': 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'ARCHIVE',
        LOCK_FOR: [
            'films',
            'serials',
            'infographic',
            "episodes",
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
        WORKFLOW_FOR: [
            'films',
            'serials',
            'episode',
            "episodes",
            'article',
            'news',
            'gallery',
            'meaning'
        ],
    },
    WIDGETS: {
        WTYPE: ['main', 'films', 'serials', 'ads', 'hub_main', 'hub_media'],
        VIEWS: [
            {name: 'Главный слайдер', key: 'main_slider'},
            {name: 'Слайдер с маленькими картинками', key: 'slider_min_images'},
            {name: 'Слайдер со средними картинками', key: 'slider_middle_images'},
            {name: 'Слайдер с большими картинками', key: 'slider_big_images'},
            {name: 'TOP 10', key: 'top_ten'},
            {name: 'Подборки', key: 'selections'},
            {name: 'Фильм', key: 'film'},
            {name: 'Выборки', key: 'preset'},
            // hub
            {name: '[ХАБ] Главный слайдер', key: 'hub_main_slider'},
            {name: '[ХАБ] Карточки', key: 'hub_slider_cards'},
            {name: '[ХАБ] темы', key: 'hub_themes'},
            {name: '[ХАБ] медиа', key: 'hub_media'},
            {name: '[ХАБ] главный материал', key: 'main_material'},
            {name: '[ХАБ] большой баннер', key: 'big_banner'},
        ],
        TYPES: ['Films', 'Serials', 'Arteldoc', 'Tags', 'Trends', 'Category', 'Country', 'Episodes', 'Banner', 'News', 'Stories', 'Stories_cards', 'Quiz', 'Page']
    },


    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdn.arteldoc.com/images",
            M3U8: "https://cdn.arteldoc.com/m3u8",
            VIDEO: "http://localhost:9106/videos",
            PDF: "https://files.vm.ru/pdf",
            AUDIO: "http://localhost:9106/stt/",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg', 'gif', 'png', 'jpeg', 'mp4', 'mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            // if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            return path;
        },
    },

};

export default test_config;
